.splitTwo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
  width: 100%;
  grid-column-gap: 20px;
}

@media (max-width: 767px) {
  .splitTwo {
    grid-template-columns: 1fr;
    /* Change to a single column for mobile devices */
  }
}

.mainBox {
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10% 1fr 1fr;
}

.supSplitTwo {
  display: grid;
  grid-template-rows: 1fr fit-content(100%);
}

.objetivoDiv {
  background-color: #ffbb00;
  text-align: center;
  width: 100%;
  position: relative;
  font-size: 1.5em;
  font-weight: bold;
  border-radius: 30px;

}

.objetivoDivV {
  background-color: #06f100;
  text-align: center;
  width: 100%;
  position: relative;
  font-size: 1.5em;
  font-weight: bold;
  border-radius: 20px;
  min-height: 1.7em;

}

.objetivoDivV2 {
  min-height: 1.6em;
  background-color: #d6d6d6;
  text-align: center;
  color: black;
  border: 1px solid rgb(100, 100, 99);
  width: 100%;
  position: relative;
  font-size: 1.5em;
  font-weight: bold;
  border-radius: 20px;
  text-transform: uppercase;

}

.centerINP {
  text-align: center;
}

.tbR select {
  min-width: 200px
}

.boxEmpresaGeneral {
  margin-top: 5%;
  width: 100%
}

.boxEmpresas2 {
  gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 3fr;
}

/* Media query for screens smaller than 768px */
@media (max-width: 767px) {
  .boxEmpresas2 {
    grid-template-columns: 1fr 1fr;
  }
}

/* Media query for screens smaller than 576px */
@media (max-width: 575px) {
  .boxEmpresas2 {
    grid-template-columns: 1fr;
  }
}

.objeNum {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  right: 3%;
  font-size: 2.5em;
  font-weight: bold;
  text-transform: uppercase;
  color: black;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 0 20px #0000001a;

}

body {
  background-color: white;
}

.logoFooter {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  margin: 0 auto;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

}

.mesFooter {
  font-size: 1.8em;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: left !important;
  flex-direction: column;
}

.mesFooterMin {
  font-size: 1.0em;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: left !important;
  flex-direction: column;
}

.fraseFooter {
  font-size: 2.9em;
  line-height: 1.0em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: left !important;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 20px;
  text-align: center;
}

td.centertd {

  cursor: pointer;
  display: flex;
  justify-content: center;
  /* centra horizontalmente */
  align-items: center;
  /* centra verticalmente */
}

.centertd div {
  margin-top: 10px;

}

.splitTwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(8, 1fr);
  height: 100%;
  width: 100%;
  grid-column-gap: 20px;
}

@media (max-width: 767px) {
  .splitTwo {
    grid-template-columns: 1fr;
    /* Change to a single column for mobile devices */
  }
}

.left {
  grid-column: 1 / 2;
}

.right {
  grid-column: 2 / 3;
}

.header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-column-gap: 20px;
}

.itemHeader {
  display: grid;
  grid-template-columns: 10% 50% 10% 10% 10% 10%;
}

.itemHeader * {
  margin-bottom: 0px !important;
}

.Hexp {
  text-align: center;
}

.Hsem {
  text-align: center;
}

.Hsup {
  text-align: center;
}

.Ht {
  text-align: center;
}

.mainBox {

  padding-top: 10px;
}

.delIcon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;


}

.highlight {
  background-color: #1e85a4 !important;
}

.flexSnap {
  display: flex;
  gap: 20px;
}

.objetivoBoxAdmin {
  display: grid;
  align-items: flex-start !important;
  width: 100% !important;
  cursor: pointer;
  min-width: 200px;
  min-height: 200px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
  background-color: white;
}

.centerGridObj {
  display: grid;
  grid-template-columns: 1fr 20%;
}

.tcenter {
  text-align: center !important;
}

.operadoraListTxt {
  font-size: 13px;
  text-align: center;
  margin-top: 10px;
  color: #3d5170;
}

.imgPremioPers {
  position: absolute;
  height: 50vh;
  top: 10%;
}

.campanya {
  float: right;
  margin-right: 20px;
}

.mesActual {
  font-size: 1.5em;
  font-weight: bold;
  text-transform: uppercase;
  color: black;
  padding: 10px;
  margin-top: 10px;
  background-color: white;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 58px 11px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0px 0px 58px 11px rgba(0, 0, 0, 0.34);
  box-shadow: 0px 0px 58px 11px rgba(0, 0, 0, 0.34);
}



.midSup {
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}