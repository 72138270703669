.mainItem{
    display: grid;
    grid-template-columns: 5% 95%;
    grid-template-rows: 100%;
    margin-bottom: 15px;
    height: fit-content;
}
.imgItem{
    border-radius: 100%;
    width: fit-content;
    height: 60px;
    margin-left: -7px!important;
    align-content: left;
    position: absolute;
}
.boxImgRank{
    display: flex;
    justify-content: left!important;
    align-items: center;
}
.item {
    display: grid;
    grid-template-columns: 10% 50% 10% 10% 10% 10%;
    border-radius: 20px;
    border: 1px solid #8d8d8d8a;
    padding:0px;
    box-shadow: 0 0 20px #0000001a;
   
   
}

.divDos{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
}
.divrdos{
    display: grid;
    grid-template-columns: 21% 21% 21% 1fr;
    grid-column-gap: 20px;
    margin-left: 25px;
    margin-right: 25px;
}
.padreObj{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-height: 60px;
  
}


.itemPadre {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 2.2em;
    font-weight: bold;

}
.item * {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0;
   
}
.item h2{
    justify-content: left;
    font-size: 1.5em;
    font-weight: bold;
    text-transform: uppercase;
}
.exp{
    font-size: 1.6em;
    font-weight: bold;
    text-transform: uppercase;
    color: black;
}
.sem{
    font-size: 1.3em;
    font-weight: 400;
    text-transform: uppercase;
    color: black;
}
.sup{
    font-size: 1.3em;
    font-weight: 400;
    text-transform: uppercase;
    color: black;
}
.t{
    font-size: 1.3em;
    font-weight: bold;
    text-transform: uppercase;
    color: black;
}
