@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
    font-family: "Poppins";
  }
  .main-sidebar {
    left: 0;
    top: 0;
    position: fixed;
    height: 100vh;
    background: #fff;
    z-index: 1070;
    /* will-change: transform; */
    transition: -webkit-transform .2s ease-in-out;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
}
.main-sidebar, .main-sidebar.open {
    box-shadow: 0 0.125rem 9.375rem rgb(90 97 105 / 10%), 0 0.25rem 0.5rem rgb(90 97 105 / 12%), 0 0.9375rem 1.375rem rgb(90 97 105 / 10%), 0 0.4375rem 2.1875rem rgb(165 182 201 / 10%);
}
.main-sidebar .nav .nav-item, .main-sidebar .nav .nav-link {
  white-space: nowrap;
  min-width: 100%;
  max-width: 100%;
  /*overflow: hidden;*/
  text-overflow: ellipsis;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
  will-change: background-color,box-shadow,color;
  -webkit-transition: box-shadow .2s ease,color .2s ease,background-color .2s eexpase;
  transition: box-shadow .2s ease,color .2s ease,background-color .2s ease;
  font-size: .85rem;
}
.main-sidebar .nav .nav-item .nav-link {
  border-bottom: 1px solid #e1e5eb;
  font-weight: 400;
  color: #3d5170;
  padding: 0.9375rem 1.5625rem;
}
.d-inline-block {
  display: inline-block!important;
}
.main-sidebar .nav .nav-item .nav-link.active, .main-sidebar .nav .nav-item .nav-link:hover{
  box-shadow: inset 0.1875rem 0 0 #1e85a4;
  background-color: #fbfbfb;
  color: #1e85a4;
}



.main-sidebar .nav--no-borders .nav-item .nav-link {
  border-bottom: 0;
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  margin-right: 10px;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
i.material-icons{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.main-content .header-navbar, .main-content>.main-navbar {
  box-shadow: 0 0.125rem 0.625rem rgb(90 97 105 / 12%);
}

.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
}
.bg-white {
  background-color: #fff!important;
}
@media (min-width: 992px) {
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
}

@media (max-width: 991px) {
  .offset-lg-2 {
    margin-left: 0;
  }
}

.col-lg-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

@media (max-width: 991px) {
  .col-lg-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.bg-white {
  background-color: #fff!important;
}
.main-navbar .navbar {
  height: 3.75rem;
}
.main-navbar.bg-white.sticky-top {
  height: 3.75rem;
}
.main-content>.main-navbar {
  box-shadow: 0 0.125rem 0.625rem rgb(90 97 105 / 12%);
}
.userA{
  display: flex;
  align-items: center;
  justify-content: right;
  margin-right: 2%;
  height: 100%;
  color: #1e85a4;
  font-size: 0.9rem;
 
}
.logOut{
  cursor: pointer;
  margin-left: 10px;
}
.page-header .page-subtitle {
  letter-spacing: .125rem;
  color: #818ea3;
  font-size: .625rem;
}


.page-header .page-subtitle {
    letter-spacing: .125rem;
    color: #818ea3;
    font-size: .625rem;
}

.text-uppercase {
    text-transform: uppercase!important;
}
.page-header .page-title {
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  color: #3d5170;
  padding: 0;
}
.page-header.py-4.no-gutters.row {
  margin: 0;
  padding-left: 10px;
}
.main-content{
  background: #f5f6f8;
  min-height: 100vh;
}

/* phone version */



.boxEmpresas {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  gap: 20px;
}

.boxEmpresa {
  cursor: pointer;
  min-width: 200px;
  min-height: 200px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

/* Media query for screens smaller than 768px */
@media (max-width: 767px) {
  .boxEmpresas {
    flex-wrap: wrap;
    justify-content: center;
  }

  .boxEmpresa {
    margin-bottom: 20px;
  }
}

/* Media query for screens smaller than 576px */
@media (max-width: 575px) {
  .boxEmpresa {
    min-width: 100%;
    min-height: auto;
  }
}

.fw{
  width: 100%;
}
.empresasNombre{
  color: #3d5170;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0px;
  margin: 0px;
}
.empresasTrabajadores{
  color: #3d5170;
  font-size: 2.9rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: -8px;
}
.empresasNombreT{
  color: #3d5170;
  font-size: 0.7rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0px;
  margin: 0px;
}
.boxEmpresa .selectempresa{
  margin-top: 10px;
  width: 100%;
  height: 40px;
  background-color: #1e85a4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.boxTrabajadores {
  min-width: 200px;
  min-height: 200px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
  background-color: white;
  margin-top: 20px;
}


/* Media query for screens smaller than 768px */
@media (max-width: 767px) {
  .boxTrabajadores {
    min-width: 100%;
  }
}
 .boxPantallas{
  min-width: 200px;
   min-height: 200px;
   border-radius: 5px;
   padding: 10px;
   box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
   background-color: white;
   margin-top: 20px;

 }
 .boxPantallasContainer{
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

 }

input {
  border: 1px solid #e1e5eb;
  border-radius: 0.1875rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #3d5170;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/* Media query for screens smaller than 768px */
@media (max-width: 767px) {
.table-responsive input:not([type="checkbox"]) {
  min-width: 100px!important;
}
  input {
    font-size: 0.75rem;
    padding: 0.375rem 0.5rem;
  }
}
 td{
  border:none;
  margin: 0px;
  padding-bottom: 0px!important;
  }
  tr{
    margin-top: 0px;
    margin-bottom: 0px;

  }
 .table thead tr th{
  color: #3d5170;
    font-size: 0.7rem;
    font-weight: 500;
    text-transform: uppercase;
 }
.tituloGeneral{
  color: #3d5170;
  font-size: 0.9rem;
  font-weight: 00;
  text-transform: uppercase;
  padding: 0px;
  margin-bottom: 15px;
}
.generalP{
  color: #3d5170;
  font-size: 0.8rem;

}
.tituloGeneralxs{
  color: #3d5170;
  font-size: 0.7rem;
  font-weight: 00;
  text-transform: uppercase;
  padding: 0px;
  margin-bottom: 5px;
}
.btn-main{
  background-color: #3d5170;
  color: white;
  border: none;
  border-radius: 0px;
  font-weight: 300;
  font-size: 0.9rem;
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);

}
.circlePlus{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #3d5170;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
  animation: pulse 2s infinite;

}
.circlePlus:hover{
  background-color: #1e85a4;
}
.circlePlus i{
  margin: 0px;
  padding: 0px;
}
.centrarDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/*
haz una animacion pulso infinita y que se vea mucho mejor

*/

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
}

.boxFelicidades{
  position: absolute;
  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 99;
  color:wheat;
}
.mauto{
  margin: auto;
}

.simPantalla{
  width: 100%;
  height: 50vh;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
  background-color: white;
  margin-top: 20px;
}
.boxMiniContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

@media (max-width: 767px) {
  .boxMiniContainer {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .boxMiniContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .boxMiniContainer {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.boxMaxiContainer {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

@media (max-width: 767px) {
  .boxMaxiContainer {
    grid-template-columns: 1fr;
  }

  .boxMaxiContainer:last-child {
    margin-bottom: 10vh;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .boxMaxiContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .boxMaxiContainer {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.boxMini{
  min-width: 200px;
  min-height: 100px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
  background-color: white;
  margin-top: 20px;
  height: 100%;
  display: grid;
  grid-template-columns: 30% 1fr;
  grid-gap: 10%;

  
}
.centerTxt{
  margin: auto;
}

.subGdocs{
  display: grid;
  grid-template-columns: 20% 1fr 20%; 
  grid-gap: 5px;
  margin-bottom: 5px;
}

.boxMini2{
  min-width: 200px;
  min-height: 100px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
  background-color: white;
  margin-top: 20px;
  height: 100%;

  
}
.boxMaxi {
  min-width: 200px;
  min-height: 400px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
  background-color: white;
  margin-top: 20px;
  height: 100%;
}

@media (max-width: 767px) {
  .boxMaxi {
    min-width: 100%;
    min-height: 200px;
    margin-top: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .boxMaxi {
    min-width: 50%;
    min-height: 300px;
    margin-top: 15px;
  }
}

@media (min-width: 1024px) {
  .boxMaxi {
    min-width: 30%;
    min-height: 400px;
    margin-top: 20px;
  }
}

.boxTituloPremio{
  font-size: 1.01rem;
  color: #3d5170;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0px!important;
}
.boxTituloPremioB{
  font-size: 1.30rem;
  color: #3d5170;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px!important;
}
.boxSubTituloPremio{
  font-size: 0.8rem;
  color: #3d5170;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0px!important;
}
.boxPremio{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 75%;

}
.marginTop{
  margin-top: 20px;
}

.insideboxMaxiItem{
  margin-top: 10px;
  display : grid;
  grid-template-columns: 1fr 45%;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
  border-radius: 5px;
  padding: 15px;
  

}
.stylePlay{
  font-size: 16px;
  float: left;
}
.empresaInsideMaxi{
font-size: 0.8rem;
color: #3d5170;
font-weight: 500;
text-transform: uppercase;
font-style: italic;
}
.insideboxMaxiItemTitulo{
  margin-bottom: 0px!important;
  
}
.insideboxMaxiItemSubTitulo{
  margin-bottom: 0px!important;
 text-align: right;
}
.insideboxMaxiItemSub2{
  margin-bottom: 0px!important;
  text-align: right;
  color: #d55e5e;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.boxMiniTitulo{
  font-size: 2.9rem;
  color: rgb(76, 175, 80);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.boxMiniSubtitulo{
  font-size: 1.25rem;
  color: rgb(76, 175, 80);
    font-weight: 500;
    letter-spacing: .0125em;
    line-height: 2rem;
    word-break: break-al
}

.boxMiniSubtitulosub{
  font-size: 0.90rem;
  color: #3d5170;

  
}

.boxMaxiButton{
  border: none;
  background-color: #3d5170;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  right: 0;
    bottom: 0;

}
.boxMaxiButtonCancel{
  border: none;
;
  color: #3d5170;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  right: 0;
  bottom: 0;
  margin-right: 5px;
}
.popup{
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
  border-radius: 5px;
  padding: 20px ; 
  width: 20vw;
  height:18vh;
 position: relative;
}
@media (max-width: 575px) {
  .popup {
    width: 100%;
    height: 100%;
  }
  .bottomPop {
    margin-top: 10px;
    position: relative!important;
   }
}


.bottomPop{
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 10px;
  margin-right: 10px;
}
.inputPantalla{
position: relative;

width:2.75rem;
}
.boxPremioDos{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;


}
.flexCenter{
  display: flex;
  align-items: center;
  justify-content: center;
}

.contenedor{
  display: flex;
}
.capas{
  width:50%;
}
.opciones{
  width:50%;
}
.capa{
  padding: 10px;
  border: solid 1px #CCC;
  border-radius: 3px;
  margin-bottom: 8px;
  background-color: #fff;
}

.listadoDrag{
  padding: 10px;
  border: solid 1px #CCC;
  border-radius: 3px;
  margin-bottom: 8px;
  background-color: #fff;
  height: 100%;
  display: grid;
  grid-template-columns: 70% 1fr ;
  
}

.ListadoBox{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-gap: 20px;
}

@media (max-width: 768px) {
  .ListadoBox {
    grid-template-columns: 1fr;
  }
}



.marginTopBox{
  margin-top: 20px;
}

.timeOrden{
  font-size: 0.8rem;
  color: #3d5170;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0px!important;
  text-align: right;  
}

.listado2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-right: 20px;
}

@media (max-width: 768px) {
  .listado2 {
    grid-template-columns: 1fr;
  }
}
.boxOpciones{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  background-color: white;
  border: solid 1px #CCC ;
  height: 200px;
}

.itemClicked {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: white;
  border: solid 1px #CCC;
  border-radius: 5px;
  padding: 20px;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 768px) {
  .itemClicked {
    top: 40%;
    width: 80%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .itemClicked {
    top: 40%;
    width: 90%;
    height: auto;
  }
}
.bgPop{
  /*
    quiero que ocupe toda la pantalla i que se vea el fondo oscuro
  */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 99;
  
}
.closePop{
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
  color: #3d5170;
  font-size: 1.1rem;
  font-weight: 500;
}
.popupContent{
  width: 100%;
  height: 100%;
 
}
.insidePopTitle{
  font-size: 0.8rem;
  color: #3d5170;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 5px!important;
  
}
.popupBody {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

@media (max-width: 768px) {
  .popupBody {
    grid-template-columns: 1fr;
  }
}

.popupBodyType{
  margin-top: 20px;
  text-align: center;
}
.fotosCenter{
  display: flex;
  align-items: center;
  justify-content: center;
}

.listadoItem{
  display: grid;
  grid-template-columns:  1fr 5%;
  grid-gap: 20px;
  margin-right: 20px;
}
.btn-flotante{
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  background-color: #3d5170;
  color: white;
  font-size: 1.1rem;
  cursor: pointer;
  z-index: 999;
  background-color: #3d5170;
  color: white;
  border: none;
  border-radius: 0px;
  font-weight: 300;
  font-size: 0.9rem;
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
}
.boxPremioZ{
  
  z-index: 999;
}

.fullPantalla{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}
.centerMiniBox{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}



.w100{
  width: 100%;
  margin-top: 0px;
}
.totalFlotante{
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 15px;
  margin-bottom: 0px;
  margin-right: 10px;
  background-color: #3d5170;  
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
}
.boxAvatar{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
}
.avatar{
  cursor: pointer;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: solid 1px #CCC;
  margin: auto;

}
.editIcon{
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #3d5170;
  font-weight: 500;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: solid 1px #CCC;
}
.editIcon span{
  margin-right: 0px!important;
  font-size: 11px!important;
}

.popupBody1{
  margin-top: 20px;
 
}
.minTxt{
  margin-top: 5px;
  font-size: 0.8rem;
}
.mt10{
  margin-top: 10px;
}
.delCapa{
  cursor: pointer;
}
.dividerInsideBox{
display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: 20px;


}

  /* inner wrapper: make responsive */

  .responsive-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
  }
  /* padding-bottom = h/w as a % */
  .responsive-wrapper-wxh-760x1200 {
  padding-bottom: 363.6364%;
  }
  .iframe-border {
  border: 1px solid #000;
  }
  .center-block-horiz {
  margin-left: auto !important;
  margin-right: auto !important;
  }


.logoFooter{
  max-height: 120px;
}



.smallSpan{
  font-size: 0.6rem;
  color: #3d5170;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 5px!important;
}
span.delCapa {
  float: right;
  color: rgb(163, 11, 11);
  cursor: pointer;
}
.editText{
  cursor: pointer;
}
.selectedPantallaB {
  color: #4caf50!important;
}
p.insideboxMaxiItemSubTitulo {
  color: rgb(165, 38, 38);
  font-size: 18px!important;
}
.depuracio{
  width: fit-content;
  margin-left: 20px;
}
.tipusPremi{

    border: 1px solid #e1e5eb;
    border-radius: 0.1875rem;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #3d5170;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}
.minVideoTxt{
  font-size: 11px;
  color: #3d5170;
}

.publiSup{

}
.divSupPubli{
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 50%;


}
.divSupPubli span{
  display: flex;
  align-items: center;
  justify-content: flex-end;

}

.btnTrim{
  float: right;
  background-color: #d36d6d!important;
}
.btnMes{
  float: right;
  margin-right: 10px;
  background-color: #f5b427!important;
}

@media (max-width: 767px) {
  .responsive-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .responsive-container button,
  .responsive-container input {
    flex-basis: 100%;
    width: 100%!important;
    margin-left: 0!important;
    margin-right: 0!important;
    margin-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .page-header {
    display: block;
  }
}

@media (min-width: 768px) {
  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

}@media (max-width: 767px) {
  .page-header.py-4.no-gutters.row {
    max-width: 100vw!important;
    display: block;
  }
}

@media (min-width: 768px) {
  .page-header.py-4.no-gutters.row {
    max-width: 100vw!important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}


/* Media query for screens smaller than 768px (phone screens) */
@media (max-width: 767px) {
  .main-content {
    position: relative;
    overflow-y: scroll;
    height: calc(100vh - 100px);
  }

  .page-header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    padding: 10px;
  }

  .box {
    width: 100%;
  }
}

/* Media query for screens larger than 768px (desktop screens) */
@media (min-width: 768px) {
  .main-content {
    position: static;
    overflow-y: visible;
    height: auto;
  }

  .page-header {
    position: static;
    top: auto;
    background-color: transparent;
    z-index: auto;
    padding: 20px;
  }

  .box {
    width: 48%;
  }
}
div#opBox3 {
  zoom: 75%;
}
div#opBox2 {
  zoom: 75%;
}