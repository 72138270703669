@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
    font-family: "Poppins";
  }
  input{
    width: 100%;
  }
  
  img.logo {
    padding: 47px;
    width: fit-content;
    margin: 0 auto;
}
.d-table.m-auto {
  padding-top: 10px;
  padding-bottom: 10px;
}
  body {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
            /*
            MIRAR AQUI
            */
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dde5f4;
    height: 100vh;
  }
  
 .screen-1 {
  background: #f1f7fe;
  padding: 2em;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  gap: 2em;
}

@media (max-width: 768px) {
  .screen-1 {
    width: 98%;
    padding: 1em;
    border-radius: 20px;
    gap: 1em;
  }
}

@media (max-width: 480px) {
  .screen-1 {
    padding: 0.5em;
    border-radius: 10px;
    gap: 0.5em;
  }
}
  .screen-1 .logo {
    margin-top: -3em;
  }
  .screen-1 .email {
    background: white;
    box-shadow: 0 0 2em #e6e9f9;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    border-radius: 20px;
    color: #4d4d4d;
    margin-top: -3em;
  }
  .screen-1 .email input {
    outline: none;
    border: none;
  }
  .screen-1 .email input::-moz-placeholder {
    color: black;
    font-size: 0.9em;
  }
  .screen-1 .email input:-ms-input-placeholder {
    color: black;
    font-size: 0.9em;
  }
  .screen-1 .email input::placeholder {
    color: black;
    font-size: 0.9em;
  }
  .screen-1 .email ion-icon {
    color: #4d4d4d;
    margin-bottom: -0.2em;
  }
  .screen-1 .password {
    background: white;
    box-shadow: 0 0 2em #e6e9f9;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    border-radius: 20px;
    color: #4d4d4d;
  }
  .screen-1 .password input {
    outline: none;
    border: none;
  }
  .screen-1 .password input::-moz-placeholder {
    color: black;
    font-size: 0.9em;
  }
  .screen-1 .password input:-ms-input-placeholder {
    color: black;
    font-size: 0.9em;
  }
  .screen-1 .password input::placeholder {
    color: black;
    font-size: 0.9em;
  }
  .screen-1 .password ion-icon {
    color: #4d4d4d;
    margin-bottom: -0.2em;
  }
  .screen-1 .password .show-hide {
    margin-right: -5em;
  }
  .screen-1 .login {

    padding: 1em;
    background: #4f84a1;
    color: white;
    border: none;
    border-radius: 30px;
    font-weight: 600;
  }
  .screen-1 .footer {
    display: flex;
    font-size: 0.7em;
    color: #5e5e5e;
    gap: 14em;
    padding-bottom: 5em;
  }
  .screen-1 .footer span {
    cursor: pointer;
  }
  button.login:hover {
    box-shadow: 0 0 2em #e6e9f9;
    background: white;
    color: #4f84a1;
    
  }
  button.login {
    transition: 0.5s;

  
  }
  button {
    cursor: pointer;
  }